<template>
    <div class="misc-no-results">
        <picture class="misc-no-results__image">
            <img :src="map[mode]" alt="icon" />
        </picture>
        <div class="space-y-2">
            <h4 v-if="title" class="misc-no-results__title" v-html="title"></h4>
            <p v-if="subtitle" class="misc-no-results__subtitle" v-html="subtitle"></p>
            <button
                v-if="button && button.handler"
                :class="button.color"
                class="misc-no-results__button"
                type="button"
                @click="button.handler"
            >
                <img v-if="button.icon" :src="button.icon" width="12px" height="12px" alt="icon" />

                <span>{{ button.text }}</span>
            </button>
            <NuxtLink
                no-prefetch
                v-if="button && button.path"
                :to="button.path"
                :class="button.color"
                class="misc-no-results__button"
                rel="follow"
            >
                <img v-if="button.icon" :src="button.icon" width="12px" height="12px" alt="icon" />
                <span>{{ button.text }}</span></NuxtLink
            >
        </div>
    </div>
</template>

<script lang="ts">
import { type PropType, defineComponent } from 'vue'

export default defineComponent({
    name: 'MiscNoResults',
    props: {
        button: Object as PropType<{
            color: 'primary' | 'secondary'
            text: string
            icon?: string
            path?: string
            handler?: () => any | void
        }>,
        mode: {
            type: String as PropType<
                | 'discounts'
                | 'megaphone'
                | 'rewards'
                | 'rewards-won'
                | 'saved-discounts'
                | 'activities'
                | 'comments'
                | 'chats'
                | 'no-prizes'
                | 'no-prizes-available'
            >,
            default: 'discounts',
        },
        title: String,
        subtitle: String,
    },
    data() {
        return {
            map: {
                discounts: this.$assets.gray.noDiscounts,
                megaphone: this.$assets.illustration.noDiscountsFoundTwo,
                rewards: this.$assets.gray.noRewards,
                'rewards-won': this.$assets.gray.noRewardsWon,
                'saved-discounts': this.$assets.gray.noSavedOffers,
                activities: this.$assets.gray.noActivities,
                comments: this.$assets.gray.noComments,
                chats: this.$assets.gray.noChats,
                'no-prizes': this.$assets.gray.noPrizes,
                'no-prizes-available': this.$assets.gray.noPrizesAvailable,
            },
        }
    },
})
</script>

<style lang="postcss" scoped>
.misc-no-results {
    @apply px-2 pb-4 pt-6 text-center text-gray-800;
    &__image {
        @apply mx-auto mb-4 block h-24 w-24 lg:h-40 lg:w-40;
        img {
            @apply h-full w-full object-contain;
        }
    }
    &__title {
        @apply text-xl font-semibold;
    }
    &__subtitle {
        @apply text-base;
    }
    &__button {
        @apply mx-auto flex max-w-max items-center space-x-2 rounded-md px-10 py-2 font-medium text-white;
        img {
            @apply h-4 w-4;
        }
        &.primary {
            @apply bg-site-primary;
        }
        &.secondary {
            @apply bg-site-secondary;
        }
    }
}
</style>
